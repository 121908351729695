:root {
  --c-black: #000;
  --c-white: #fff;
  --c-gray-50: #f7fafc;
  --c-gray-100: #f4f9ff;
  --c-gray-200: #e0e8f6;
  --c-gray-300: #c6d0e0;
  --c-gray-400: #90a0ba;
  --c-gray-500: #657288;
  --c-gray-600: #525f70;
  --c-gray-700: #2d3748;
  --c-gray-800: #1a202c;
  --c-gray-900: #171923;
  --c-brand-infinum-200: #d82828;
  --c-brand-infinum-500: #d82828;

  --border-none: 0;
  --border-1px: 1px solid;
  --border-2px: 2px solid;
  --border-4px: 4px solid;
  --border-8px: 8px solid;

  --fontSize-3xs: 0.45rem;
  --fontSize-2xs: 0.625rem;
  --fontSize-xs: 0.75rem;
  --fontSize-sm: 0.875rem;
  --fontSize-md: 1rem;
  --fontSize-lg: 20px;
  --fontSize-xl: 1.25rem;
  --fontSize-2xl: 1.5rem;
  --fontSize-3xl: 1.875rem;
  --fontSize-4xl: 2.25rem;
  --fontSize-5xl: 3rem;
  --fontSize-6xl: 3.75rem;
  --fontSize-7xl: 4.5rem;
  --fontSize-8xl: 6rem;
  --fontSize-9xl: 8rem;

  --fontWeight-hairline: 100;
  --fontWeight-thin: 200;
  --fontWeight-light: 300;
  --fontWeight-normal: 400;
  --fontWeight-medium: 500;
  --fontWeight-semibold: 600;
  --fontWeight-bold: 700;
  --fontWeight-extrabold: 800;
  --fontWeight-black: 900;

  --letterSpacing-tighter: -0.05em;
  --letterSpacing-tight: -0.025em;
  --letterSpacing-normal: 0;
  --letterSpacing-wide: 0.025em;
  --letterSpacing-wider: 0.05em;
  --letterSpacing-widest: 0.1em;

  --lineHeight-3: 0.75rem;
  --lineHeight-4: 1rem;
  --lineHeight-5: 1.25rem;
  --lineHeight-6: 1.5rem;
  --lineHeight-7: 1.75rem;
  --lineHeight-8: 2rem;
  --lineHeight-9: 2.25rem;
  --lineHeight-10: 2.5rem;
  --lineHeight-normal: normal;
  --lineHeight-none: 1;
  --lineHeight-shorter: 1.25;
  --lineHeight-short: 1.375;
  --lineHeight-base: 1.5;
  --lineHeight-tall: 1.625;
  --lineHeight-taller: 2;

  --radii-none: 0;
  --radii-sm: 0.125rem;
  --radii-base: 0.25rem;
  --radii-md: 0.375rem;
  --radii-lg: 0.5rem;
  --radii-xl: 0.75rem;
  --radii-2xl: 1rem;
  --radii-3xl: 1.5rem;
  --radii-full: 9999px;

  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-5: 1.25rem;
  --space-6: 1.5rem;
  --space-7: 1.75rem;
  --space-8: 2rem;
  --space-9: 2.25rem;
  --space-10: 2.5rem;
  --space-12: 3rem;
  --space-14: 3.5rem;
  --space-16: 4rem;
  --space-20: 5rem;
  --space-24: 6rem;
  --space-28: 7rem;
  --space-32: 8rem;
  --space-36: 9rem;
  --space-40: 10rem;
  --space-44: 11rem;
  --space-48: 12rem;
  --space-52: 13rem;
  --space-56: 14rem;
  --space-60: 15rem;
  --space-64: 16rem;
  --space-72: 18rem;
  --space-80: 20rem;
  --space-96: 24rem;
  --space-px: 1px;
  --space-0-5: 0.125rem;
  --space-1-5: 0.375rem;
  --space-2-5: 0.625rem;
  --space-3-5: 0.875rem;
}
